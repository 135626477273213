import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import CtaBanner from "../components/cta-banner";
import Navigation from "../components/navigation";
import PhotoRight from "../components/photo-right";
import PhotoLeft from "../components/photo-left";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import SVGZoomDiscovery from "../components/SVG/zoom-discovery-svg";
import SVGIdentify from "../components/SVG/identify-svg";
import SVGContentCreated from "../components/SVG/content-created-svg";

const ServicesPage = () => {
	const data = useStaticQuery(graphql`
		query {
			copywritingImg: wpMediaItem(title: { eq: "Info-Panel-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			copyCoachingImg: wpMediaItem(title: { eq: "Home-Hero" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			translationImg: wpMediaItem(title: { eq: "Blog-Hero" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const copywritingImg =
		data.copywritingImg.localFile.childImageSharp.gatsbyImageData;
	const copyCoachingImg =
		data.copyCoachingImg.localFile.childImageSharp.gatsbyImageData;
	const translationImg =
		data.translationImg.localFile.childImageSharp.gatsbyImageData;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "1",
				name: "Services",
				item: {
					url: `${siteUrl}/services`,
					id: `${siteUrl}/services`,
				},
			},
		],
	};

	return (
		<Layout>
			<Navigation />
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title="Services"
				description="Captivating copy, ghostwriting and spot-on translations. Are you looking for a copywriter to increase your sales?"
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/services`,
					title: "Writing and Translation Services",
					description:
						"Captivating copy, ghostwriting and spot-on translations. Are you looking for a copywriter to increase your sales?",
					images: [
						{
							url: `https://arc-writing.rjmdigital.net/wp-content/uploads/2021/11/NFP_AC_06-thumb.jpg`,
							width: 840,
							height: 561,
							alt: "Writing and Translation Services",
						},
					],
				}}
			/>
			<section className="py-md-7 py-5">
				<PhotoRight
					url="/services/ghostwriting"
					imgWidth="25rem"
					image={copywritingImg}
					imgAlt={data.copywritingImg.altText}
					title="Ghostwriting"
					text={
						<>
							<p>
								Do you long to see your non-fiction book come to life, but find
								the idea of the writing process overwhelming?
							</p>
							<p>
								Let my professional ghostwriting service guide you in
								transforming your stories into a captivating manuscript that
								establishes you as a thought leader and opens doors to
								unbelievable speaking and media opportunities.
							</p>
						</>
					}
				/>
				<section className="py-5 py-md-0">
					<PhotoLeft
						url="/services/copywriting"
						imgWidth="27rem"
						image={copyCoachingImg}
						imgAlt="Copywriting"
						title="Copywriting"
						text={
							<>
								<p>
									Are you struggling to generate leads and drive traffic to your
									website? Would you like to increase your sales and get your
									message across to the right audience, in the right voice using
									the right words? Then you’ve come to the right place.
								</p>
								<p>
									ARC Writing and Translation Services can help you to grow your
									business by creating engaging, persuasive copy and content
									that speaks directly to YOUR customers.
								</p>
							</>
						}
					/>
				</section>
				<PhotoRight
					url="/services/translation"
					imgWidth="30rem"
					image={translationImg}
					imgAlt={data.translationImg.altText}
					title="Translation"
					text={
						<>
							<p>
								I offer Italian into English translation, French into English
								translation and Spanish into English translation services for
								individual and corporate clients and agencies in London and
								worldwide.
							</p>
							<p>
								I have worked as a full-time translator since being awarded my
								Master’s degree in Translation in 2007. I have translated a
								variety of texts over the years and now specialise in marketing
								translations, financial translations, pharmaceutical
								translations and technical translations. I use the latest
								translation software provided by SDL Trados. I am a certified
								member of the Institute of Translation and Interpreting as well
								as being a member of the ITI’s local Weald translators’ network.
							</p>
						</>
					}
				/>
			</section>
			<section className="position-relative  text-center">
				<div
					style={{ zIndex: "-3" }}
					className="position-absolute w-100 h-100 bg-light-background  "
				></div>
				<Container className="py-5  ">
					<Row>
						<Col>
							<h1 className="underline">My Approach</h1>
							<p className="fs-5 mb-5  fw-light">
								You get two free rounds of review before signing off the project
							</p>
						</Col>
					</Row>
					<Row>
						<Col className="px-4" lg={4}>
							<SVGZoomDiscovery className="mw-20 mb-3 h-25" />
							<h3 className="normal-font fw-bold">Zoom discovery</h3>
							<p className="mb-5 ">
								We will have a FREE 30-minute Zoom discovery meeting during
								which we will discuss your requirements.
							</p>
						</Col>
						<Col className="px-4" lg={4}>
							<SVGIdentify className="mw-20 mb-3 h-25" />
							<h3 className="normal-font fw-bold">
								Identify your ideal client
							</h3>
							<p className="mb-5 ">
								We will have another Zoom meeting to identify your ideal client,
								establish the correct tone of voice and discuss the keywords for
								your on-page SEO and I will find out all about your business.
							</p>
						</Col>
						<Col className="px-4" lg={4}>
							<SVGContentCreated className="mw-20 mb-3 h-25" />
							<h3 className="normal-font fw-bold">Content created</h3>
							<p className="mb-5 ">
								I will create content that perfectly suits your needs using
								engaging copy that resonates with your target audience and is
								packed full of YOUR personality.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<CtaBanner
				headline={
					<>
						I'd love to hear more about your
						<br className="d-none d-md-block" /> needs, and how I can help.
					</>
				}
				bgColor="dark-background"
				btnTxt="Contact"
				btnLink="/contact"
			/>
		</Layout>
	);
};

export default ServicesPage;
