import * as React from "react";

const SVGZoomDiscovery = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={124.498}
		height={117.044}
		viewBox="0 0 124.498 117.044"
		{...props}
	>
		<g id="Layer_2" data-name="Layer 2" transform="translate(-9 6)">
			<g id="Layer_1" data-name="Layer 1" transform="translate(11 -4)">
				<rect
					id="Rectangle_139"
					data-name="Rectangle 139"
					width={120.498}
					height={65.789}
					rx={8.36}
					transform="translate(0 0)"
					fill="none"
					stroke="#c157a1"
					strokeMiterlimit={10}
					strokeWidth={4}
				/>
				<path
					id="Path_33"
					data-name="Path 33"
					d="M108.835,19.271V42.733c0,2.41-1.664,4.351-3.711,4.351H74.42l-.544.874-3.125,4.959-3.1-5.023-.533-.874H62.4c-2.058,0-3.711-1.941-3.711-4.351v-23.4c0-2.4,1.653-4.351,3.711-4.351h42.722C107.171,14.909,108.835,16.861,108.835,19.271Z"
					transform="translate(-0.366 -3.274)"
					fill="none"
					stroke="#c157a1"
					strokeMiterlimit={10}
					strokeWidth={3}
				/>
				<rect
					id="Rectangle_141"
					data-name="Rectangle 141"
					width={19.633}
					height={26.683}
					transform="translate(50.433 65.768)"
					fill="none"
					stroke="#c157a1"
					strokeMiterlimit={10}
					strokeWidth={4}
				/>
				<path
					id="Path_34"
					data-name="Path 34"
					d="M5.51,0H19.929V55.456H5.51A5.47,5.47,0,0,1,0,50.027V5.428A5.47,5.47,0,0,1,5.51,0Z"
					transform="translate(87.961 93.115) rotate(90)"
					fill="none"
					stroke="#c157a1"
					strokeMiterlimit={10}
					strokeWidth={4}
				/>
				<path
					id="Path_36"
					data-name="Path 36"
					d="M65.256,29.726h36.558c1.048-.049,1.859-.611,1.859-1.288s-.811-1.239-1.859-1.288H65.256c-1.048.049-1.859.611-1.859,1.288S64.208,29.677,65.256,29.726Z"
					transform="translate(-0.053 -3.528)"
					fill="#c157a1"
				/>
				<path
					id="Path_43"
					data-name="Path 43"
					d="M65.256,29.726h36.558c1.048-.049,1.859-.611,1.859-1.288s-.811-1.239-1.859-1.288H65.256c-1.048.049-1.859.611-1.859,1.288S64.208,29.677,65.256,29.726Z"
					transform="translate(-0.053 -8.86)"
					fill="#c157a1"
				/>
				<path
					id="Path_44"
					data-name="Path 44"
					d="M65.256,29.726h36.558c1.048-.049,1.859-.611,1.859-1.288s-.811-1.239-1.859-1.288H65.256c-1.048.049-1.859.611-1.859,1.288S64.208,29.677,65.256,29.726Z"
					transform="translate(-0.053 1.804)"
					fill="#c157a1"
				/>
				<path
					id="Path_45"
					data-name="Path 45"
					d="M64.533,29.726H86.878a1.3,1.3,0,0,0,0-2.576H64.533a1.3,1.3,0,0,0,0,2.576Z"
					transform="translate(-0.053 7.136)"
					fill="#c157a1"
				/>
			</g>
		</g>
	</svg>
);

export default SVGZoomDiscovery;
