import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Link from "./link";

const CtaBanner = ({ headline, btnTxt, btnLink, bgColor }) => {
	const ctaLink = btnLink || "/contact-us";
	

	return (
		<section className={`bg-${bgColor} py-5`}>
			<Container>
				<Row className="align-items-lg-center justify-content-center">
					<Col xs={12} className="mb-4 mb-lg-0 text-center">
						<h2 className="my-auto fs-1 mb-5 text-white">{headline}</h2>
					</Col>
					<Col xs={12} md={5} lg={3} className="mx-auto text-center ">
						<Button
							as={Link}
							variant={`primary`}
							size="lg"
							className=" cta-btn w-100  text-white"
							to={ctaLink}
							id="cta-banner-button"
						>
							{btnTxt}
						</Button>
					</Col>
					
				</Row>
			</Container>
		</section>
	);
};

export default CtaBanner;
