import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Link from "./link";
import { GatsbyImage } from "gatsby-plugin-image";

const PhotoRight = ({ title, text, image, imgAlt, imgWidth, url }) => {
	return (
		<>
			<GatsbyImage className=" d-md-none" image={image} alt={imgAlt} />
			<div Tag="section" className=" position-relative mb-3 mb-md-6 mb-xl-7 ">
				<div
					style={{ zIndex: "-3" }}
					className="bg-light-background top-0 h-100 w-100 d-md-none position-absolute"
				></div>
				<Container className="pt-3">
					<Row className=" pt-3 px-md-3 justify-content-center justify-content-xl-start">
						<Col lg={9}>
							<div className="position-relative py-xl-7 ">
								<div
									style={{ zIndex: "-3" }}
									className="bg-light-background d-none d-md-block top-0 h-100 w-100 position-absolute"
								></div>

								<div className="position-absolute d-none d-xl-block top-50 end--30  translate-middle-y">
									<GatsbyImage
										style={{ maxWidth: imgWidth }}
										image={image}
										alt={imgAlt}
									/>
								</div>

								<GatsbyImage
									className=" d-none d-md-block d-xl-none"
									image={image}
									alt={imgAlt}
								/>

								<Col
									xl={{ span: 7, offset: 1 }}
									className="pl-0 px-2 pe-lg-0 pb-3 pb-xl-0 ps-md-3"
								>
									<h2 className="pt-3 mb-3" id="services-hero-title">
										{title}
									</h2>
									<hr
										className="bg-primary"
										style={{ height: "2px", width: "250px", opacity: "1" }}
									/>

									<p className="mb-lg-0 fw-light">{text}</p>
									<Link
										className={`pink-link  cta-link fw-bold ${
											url ? "" : "d-none"
										}`}
										to={url}
									>
										{">"} Read More
									</Link>
								</Col>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
};

export default PhotoRight;
