import * as React from "react";

const SVGIdentify = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={104.48}
		height={117.044}
		viewBox="0 0 104.48 117.044"
		{...props}
	>
		<g id="Layer_2" data-name="Layer 2" transform="translate(-0.99 -1)">
			<g id="Layer_1" data-name="Layer 1" transform="translate(2.99 3)">
				<circle
					id="Ellipse_17"
					data-name="Ellipse 17"
					cx={7.844}
					cy={7.844}
					r={7.844}
					transform="translate(48.103 37.623)"
					fill="none"
					stroke="#c157a1"
					strokeMiterlimit={10}
					strokeWidth={4}
				/>
				<path
					id="Path_26"
					data-name="Path 26"
					d="M17.14,104.291v4.459a7.294,7.294,0,0,0,7.294,7.294H97.988a7.286,7.286,0,0,0,7.286-7.294V10.294A7.286,7.286,0,0,0,97.988,3H24.434a7.294,7.294,0,0,0-7.294,7.294V86.553"
					transform="translate(-4.793 -3)"
					fill="none"
					stroke="#c157a1"
					strokeMiterlimit={10}
					strokeWidth={4}
				/>
				<path
					id="Path_27"
					data-name="Path 27"
					d="M43.021,86.14,13.713,115.492a6.287,6.287,0,1,1-8.874-8.908L33.633,77.79l.681.873a32.467,32.467,0,0,0,8.708,7.478Z"
					transform="translate(-2.99 -12.534)"
					fill="none"
					stroke="#c157a1"
					strokeMiterlimit={10}
					strokeWidth={4}
				/>
				<path
					id="Path_28"
					data-name="Path 28"
					d="M45.9,69.655c.061.1.113.174.175.253a22.764,22.764,0,0,0,3.167,3.673c.314-2.5,2.277-4.738,5.235-6.291"
					transform="translate(-8.46 -11.195)"
					fill="#fff"
					stroke="#c157a1"
					strokeMiterlimit={10}
					strokeWidth={4}
				/>
				<path
					id="Path_29"
					data-name="Path 29"
					d="M36.763,113.763H93.826c2.81,0,2.81-4.363,0-4.363H36.763C33.962,109.4,33.953,113.763,36.763,113.763Z"
					transform="translate(-7.027 -16.564)"
					fill="#c157a1"
				/>
				<path
					id="Path_30"
					data-name="Path 30"
					d="M36.763,121.8H72.6c2.8,0,2.81-4.363,0-4.363H36.763C33.962,117.44,33.953,121.8,36.763,121.8Z"
					transform="translate(-7.027 -17.588)"
					fill="#c157a1"
				/>
				<path
					id="Path_31"
					data-name="Path 31"
					d="M62.822,18.4a32.283,32.283,0,0,0-25.7,51.837l.681.873a32.466,32.466,0,0,0,8.725,7.478c.471.27.942.532,1.422.777A32.283,32.283,0,1,0,62.787,18.417ZM77.655,67.968A22.755,22.755,0,0,1,54.35,71.816l-.724-.314a22.52,22.52,0,0,1-9.484-7.853c-.061-.079-.113-.157-.175-.253a22.537,22.537,0,0,1-3.926-12.713A22.782,22.782,0,1,1,77.646,67.968Z"
					transform="translate(-6.5 -4.963)"
					fill="none"
					stroke="#c157a1"
					strokeMiterlimit={10}
					strokeWidth={4}
				/>
				<path
					id="Path_32"
					data-name="Path 32"
					d="M80.1,73.872A22.755,22.755,0,0,1,56.793,77.72l-.715-.314a22.816,22.816,0,0,1-6.317-4.162c.314-2.5,2.277-4.738,5.235-6.291a21.813,21.813,0,0,1,9.929-2.234C73.248,64.72,80.037,68.821,80.1,73.872Z"
					transform="translate(-8.952 -10.868)"
					fill="none"
					stroke="#c157a1"
					strokeMiterlimit={10}
					strokeWidth={4}
				/>
			</g>
		</g>
	</svg>
);

export default SVGIdentify;
