import * as React from "react";

const SVGContentCreated = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={175.546}
		height={122.605}
		viewBox="0 0 175.546 122.605"
		{...props}
	>
		<g id="Layer_2" data-name="Layer 2" transform="translate(-1 -1)">
			<g id="Layer_1" data-name="Layer 1" transform="translate(3 3)">
				<rect
					id="Rectangle_135"
					data-name="Rectangle 135"
					width={71.16}
					height={54.323}
					rx={8.36}
					fill="none"
					stroke="#c157a1"
					strokeMiterlimit={10}
					strokeWidth={4}
				/>
				<path
					id="Path_20"
					data-name="Path 20"
					d="M175.136,75H113.3c-2.835,0-2.835,4.4,0,4.4h61.834C177.971,79.4,177.971,75,175.136,75Z"
					transform="translate(-15.918 -11.598)"
					fill="#c157a1"
				/>
				<path
					id="Path_21"
					data-name="Path 21"
					d="M175.136,63H113.3c-2.835,0-2.835,4.4,0,4.4h61.834C177.971,67.4,177.971,63,175.136,63Z"
					transform="translate(-15.918 -10.165)"
					fill="#c157a1"
				/>
				<path
					id="Path_22"
					data-name="Path 22"
					d="M179.831,51.41H149.3c-2.835,0-2.835,4.4,0,4.4h30.53C182.658,55.813,182.667,51.41,179.831,51.41Z"
					transform="translate(-20.217 -8.781)"
					fill="#c157a1"
				/>
				<path
					id="Path_23"
					data-name="Path 23"
					d="M179.831,39.41H149.3c-2.835,0-2.835,4.4,0,4.4h30.53C182.658,43.813,182.667,39.41,179.831,39.41Z"
					transform="translate(-20.217 -7.348)"
					fill="#c157a1"
				/>
				<path
					id="Path_24"
					data-name="Path 24"
					d="M27.41,31.248V17.916a2.025,2.025,0,0,1,3.038-1.673l12.134,6.666,12.143,6.675a1.876,1.876,0,0,1,0,3.329L42.582,39.587,30.448,46.253A2.025,2.025,0,0,1,27.41,44.58Z"
					transform="translate(-5.915 -4.549)"
					fill="none"
					stroke="#c157a1"
					strokeMiterlimit={10}
					strokeWidth={4}
				/>
				<path
					id="Path_25"
					data-name="Path 25"
					d="M67.872,134.378H26L33.555,121.9l3.223-5.283c5.389-8.885,14.97-8.885,20.315,0l3.223,5.283Z"
					transform="translate(-5.747 -15.772)"
					fill="none"
					stroke="#c157a1"
					strokeMiterlimit={10}
					strokeWidth={4}
				/>
				<rect
					id="Rectangle_136"
					data-name="Rectangle 136"
					width={60.469}
					height={49.312}
					transform="translate(7.758 69.293)"
					fill="none"
					stroke="#c157a1"
					strokeMiterlimit={10}
					strokeWidth={4}
				/>
				<rect
					id="Rectangle_137"
					data-name="Rectangle 137"
					width={89.229}
					height={58.118}
					transform="translate(82.317 21.742)"
					fill="none"
					stroke="#c157a1"
					strokeMiterlimit={10}
					strokeWidth={4}
				/>
				<rect
					id="Rectangle_138"
					data-name="Rectangle 138"
					width={25.387}
					height={17.022}
					transform="translate(96.406 29.957)"
					fill="none"
					stroke="#c157a1"
					strokeMiterlimit={10}
					strokeWidth={4}
				/>
				<circle
					id="Ellipse_16"
					data-name="Ellipse 16"
					cx={5.574}
					cy={5.574}
					r={5.574}
					transform="translate(17.55 79.173)"
					fill="none"
					stroke="#c157a1"
					strokeMiterlimit={10}
					strokeWidth={4}
				/>
			</g>
		</g>
	</svg>
);

export default SVGContentCreated;
